import * as React from "react";
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { CompanyContext } from "../../contexts/CompanyContext";
import { Company } from "../../interfaces/company";
import { useContext, useState } from "react";
import { Button, ImageList, ImageListItem } from "@mui/material";
import Modal from "@mui/material/Modal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const defaultTheme = createTheme();

const useStyles = makeStyles((theme) => ({
  topMain: {
    // marginTop: "20px",
  },
  topContainer: {
    // backgroundColor: '#e1edfc',
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(2),

    // position: "absolute",
    // position: "relative",
    // display: "flex",

    width: "100%",
    // height: "100%",
    height: "auto",
    // height: "100vh",
    // backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // marginTop: "20px",
    paddingTop: "20px",
  },
  container: {
    backgroundColor: "#e1edfc",
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(2),
    // marginTop: "20px",
  },
  titleBG: {
    width: "100%",
    height: "auto",
    // backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // marginTop: "20px",
    // marginBottom: "20px",
    // paddingBottom: "10px",
  },
  Link: {
    textDecoration: "none",
  },
  bgImage: {
    // position: "relative",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // zIndex: -1

    // position: "absolute",
    position: "relative",
    width: "100%",
    height: "auto",
    // height: "100%",
    // height: "100vh",
    backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  media: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
  },
  grid: {
    // marginTop: "20px",
  },
  transparentCard: {
    // backgroundColor: "transparent !important",
    // boxShadow: 'none',

    // background: "#1B03A3",
    // background: "#2d71c4",
    // background: "#4D4DFF",
    opacity: "0.90",

    // backgroundColor: rgba(255, 0, 0, 0.5),
    // backgroundColor: alpha(theme.colors.red, 0.5)
    // backgroundColor: alpha(theme.palette.background.paper, 0.85)

    // hover: {
    //   // "&:hover": {
    //     backgroundColor: 'rgb(7, 177, 77, 0.42)'
    //   // },
    // }

    "&:hover": {
      backgroundColor: "#E0FFC0 !important",
      // boxShadow: "none !important",
    },
    "&:active": {
      boxShadow: "none !important",
      backgroundColor: "#E0FFC0 !important",
    },

    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardSameHeight: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

interface ContextProps {
  company: Company | null;
  setCompany: (company: Company) => void;
}

interface Image {
  img: string;
  title: string;
}

export default function Gallery() {
  const classes = useStyles();
  const { company, setCompany } = useContext(CompanyContext) as ContextProps;

  const [open, setOpen] = useState(false);

  const [selectedImg, setSelectedImg] = useState<string | undefined>(undefined);

  const handleClick = (img: string) => {
    setSelectedImg(img);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedImg(undefined);
    setOpen(false);
  };

  const images: Image[] = [
    {
      img: "/tour_pics/1.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/2.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/3.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/4.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/23.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/6.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/7.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/8.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/24.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/10.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/11.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/12.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/13.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/14.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/15.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/16.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/17.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/18.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/19.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/20.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/21.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/22.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/25.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/26.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/27.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/28.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/29.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/30.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/31.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/32.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/33.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/34.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/35.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/36.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/53.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/54.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/39.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/55.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/41.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/42.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/43.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/44.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/45.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/46.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/47.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/48.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/49.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/56.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/57.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/58.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/59.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/60.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/61.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/62.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/63.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/64.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/65.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/66.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/67.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/68.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/69.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/70.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/71.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/72.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/73.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/74.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/75.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/76.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/77.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/78.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/79.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/80.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/81.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/82.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/83.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/84.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/85.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/86.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/87.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/88.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/89.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/90.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/91.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/92.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/93.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/94.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/95.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/96.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/97.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/98.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/99.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/100.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/101.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/102.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/103.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/104.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/105.jpeg",
      title: "Image",
    },
    {
      img: "/tour_pics/106.jpeg",
      title: "Image",
    },
  ];

  const currentIndex = images.findIndex((image) => image.img === selectedImg);

  const handlePrevious = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex < 0) return;
    setSelectedImg(images[prevIndex].img);
  };

  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex >= images.length) return;
    setSelectedImg(images[nextIndex].img);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      {/* <main className={classes.bgImage}> */}
      <main className={classes.topMain}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
          className={classes.topContainer}
        >
          <div className={classes.titleBG}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#122979"
              gutterBottom
            >
              Gallery
            </Typography>
          </div>

          <div>
            <ImageList cols={3}>
              {images.map((image) => (
                <ImageListItem
                  key={image.img}
                  onClick={() => handleClick(image.img)}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <img src={image.img} alt={image.title} />
                </ImageListItem>
              ))}
            </ImageList>

            <Modal
              open={open}
              onClose={handleClose}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <>
                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}> */}
                {/* <Button
                  onClick={handleClose}
                  sx={{
                    padding: "8px 24px",
                    borderRadius: "4px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontWeight: 600,
                  }}
                >
                  X
                </Button> */}
                {/* </div> */}
                <Button
                  onClick={handlePrevious}
                  startIcon={<ArrowBackIcon />}
                  sx={{
                    color: "white",
                    backgroundColor: "#007bff",
                    padding: "8px 24px",
                    borderRadius: "4px",
                    fontWeight: 600,
                  }}
                >
                  Prev
                </Button>
                <img src={selectedImg} alt="Tour Pic" />
                <Button
                  onClick={handleNext}
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    color: "white",
                    backgroundColor: "#007bff",
                    padding: "8px 24px",
                    borderRadius: "4px",
                    fontWeight: 600,
                  }}
                >
                  Next
                </Button>
              </>
            </Modal>
          </div>
        </Container>
      </main>
    </ThemeProvider>
  );
}
