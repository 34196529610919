import React, { ReactNode, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  ListItem,
  ListItemIcon,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  CssBaseline,
  Button,
  Drawer,
} from "@mui/material";
import { Link } from "react-router-dom";
import KiteSurfingIcon from "@mui/icons-material/Kitesurfing";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@mui/icons-material/Menu"; // Import MenuIcon component
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import AirportShuttleTwoTone from "@mui/icons-material/AirportShuttleTwoTone";
import ModeOfTravelTwoTone from "@mui/icons-material/ModeOfTravelTwoTone";
import CollectionsTwoToneIcon from "@mui/icons-material/CollectionsTwoTone";
import PermPhoneMsgTwoTone from "@mui/icons-material/PermPhoneMsgTwoTone";
import ContactPhone from "@mui/icons-material/ContactPhone";
import { CompanyContext } from "../../contexts/CompanyContext";
import { Company } from "../../interfaces/company";
import TravelExploreTwoToneIcon from "@mui/icons-material/TravelExploreTwoTone";
import { TripOrigin } from "@mui/icons-material";

// Create styles using makeStyles
const useStyles = makeStyles((theme) => ({
  kebabIcon: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),

    [theme.breakpoints.up("sm")]: {
      display: "none", // Hide kebab icon on larger screens
    },
  },
  kiteSurfingIcon: {
    marginRight: theme.spacing(2), // Default right margin value
    [theme.breakpoints.down("sm")]: {
      marginLeft: "50px", // Left margin value for screens smaller than 600px
    },
  },
  // listItem: {
  //   display: "flex",
  //   alignItems: "center",
  // },
  // link: {
  //   textDecoration: "none",
  //   color: theme.palette.text.primary,
  // },
}));

const CommonLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: #f00; /* Add your desired hover color */
    /* Add any additional hover styles here */
  }
`;

const TripAdLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: #f00; /* Add your desired hover color */
    /* Add any additional hover styles here */
  }

  img {
    padding-top: 5px;
  }
`;

const FooterLink = styled.a`
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;

  &:hover {
    color: #f00; /* Add your desired hover color */
    /* Add any additional hover styles here */
  }
`;

const Nav = styled.nav<{ theme: any }>`
  margin-left: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      display: none; /* Hide nav links in small screens */
    }

    // @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    //   display: none; /* Hide nav links in small screens */
    // }
  }

  li {
    margin-right: 20px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    a {
      margin-left: 5px;
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: 600px) {
        margin-bottom: 10px; /* Add spacing between items in mobile view */
      }
    }
  }

  a {
    text-decoration: none;

    &:hover {
      color: #f00; /* Add your desired hover color */
      /* Add any additional hover styles here */
    }
  }
`;

// Define your custom theme with spacing values
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const theme = {
  breakpoints: {
    values: {
      sm: 600, // Example breakpoint value
    },
  },
  spacing: 8, // Custom spacing value based on your preference
};

// const KebabIcon = styled.div<{ theme: any }>`
//   position: absolute;
//   top: ${(props) => props.theme.spacing(1)}px;
//   left: ${(props) => props.theme.spacing(1)}px;

//   @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
//     display: none; /* Hide kebab icon on larger screens */
//   }
// `;

interface CopyrightProps {
  company: Company | null;
}

const Copyright: React.FC<CopyrightProps> = ({ company }) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      {company && (
        <FooterLink href="/contact-us">Contact {company?.name} </FooterLink>
      )}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

interface AppLayoutProps {
  children: ReactNode;
  // window?: () => Window;
}

interface ContextProps {
  company: Company | null;
  setCompany: (company: Company) => void;
}

const drawerWidth = 240;
// const navItems = ["Home", "Tours", "Destinations", "Contact Us"];
const navItems = [
  {
    text: "Home",
    icon: <HomeTwoToneIcon style={{ fill: "white" }} />,
    to: "/",
  },
  {
    text: "Tours",
    icon: <AirportShuttleTwoTone style={{ fill: "white" }} />,
    to: "/tour-packages",
  },
  {
    text: "Destinations",
    icon: <ModeOfTravelTwoTone style={{ fill: "white" }} />,
    to: "/destinations",
  },
  {
    text: "Gallery",
    icon: <AirportShuttleTwoTone style={{ fill: "white" }} />,
    to: "/gallery",
  },
  {
    text: "Contact Us",
    icon: <ContactPhone style={{ fill: "white" }} />,
    to: "/contact-us",
  },
  {
    
    text: "Trip Advisor",
    icon: <TripOrigin style={{ fill: "white" }} />,
    to: "https://www.tripadvisor.com/Attraction_Review-g304138-d26671807-Reviews-AMP_Lanka-Kandy_Kandy_District_Central_Province.html",
  },
];

function AppLayout({ children }: AppLayoutProps) {
  const classes = useStyles();
  const { company, setCompany } = useContext(CompanyContext) as ContextProps;

  // -----------------for menu drawer
  // const { window } = children;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    // console.log("handleDrawerToggle");
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {company?.name}
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => {
          const { text, icon, to } = item;
          // console.log(item);
          return (
            <ListItem component={Link} to={to} key={text} disablePadding>
              {/* <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton> */}
              {icon && (
                <ListItemIcon sx={{ minWidth: "40px", paddingLeft: "10px" }}>
                  {icon}
                </ListItemIcon>
              )}
              <ListItemText primary={text} style={{ color: "white" }} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  // const container = window !== undefined ? () => window().document.body : undefined;
  // -----------------for menu drawer---end

  // return (
  //   <>
  //     <AppBar position="static"  component="nav">
  //       <Toolbar>
  //         {/* Kebab Icon */}
  //         {/* <KebabIcon theme={theme}> */}
  //         {/* Add your kebab icon component or button here */}
  //         {/* Example:<IconButton>...</IconButton>*/}
  //         {/* </KebabIcon> */}
  //         <div className={classes.kebabIcon}>
  //           {/* Add your kebab icon component or button here */}
  //           <IconButton edge="start" color="inherit" aria-label="menu">
  //             {/* Example of using Material-UI's Kebab Icon */}
  //             {/* Replace with your own custom icon if needed */}
  //             <MenuIcon />
  //           </IconButton>
  //         </div>

  //         {/* <KiteSurfingIcon className={classes.kiteSurfingIcon} />
  //         <Typography variant="h6" color="inherit" noWrap>
  //           <CommonLink href="/">AAA</CommonLink>
  //         </Typography> */}

  //         <TripAdLink href="/">
  //           {/* <img src="/logo/1.png" height="100px" width="100px" alt="Home" /> */}
  //           <TravelExploreTwoToneIcon
  //             color="action"
  //             fontFamily="medium"
  //             sx={{ color: "red", paddingLeft: "10px" }}
  //           />
  //           <Typography
  //             variant="h5"
  //             align="center"
  //             color="text.secondary"
  //             component="span"
  //             fontFamily="Cursive"
  //             fontSize="2.0rem"
  //           >
  //             {company?.name}
  //           </Typography>
  //         </TripAdLink>

  //         <Nav theme={theme}>
  //           <ul>
  //             <li>
  //               <HomeTwoToneIcon />
  //               <Link to="/">Home</Link>
  //             </li>
  //             {/* <ListItem className={classes.listItem}>
  //               <ListItemIcon>
  //                 <HomeTwoToneIcon />
  //               </ListItemIcon>
  //               <Typography variant="body1">
  //                 <Link to="/" className={classes.link}>
  //                   Home
  //                 </Link>
  //               </Typography>
  //             </ListItem> */}
  //             <li>
  //               <AirportShuttleTwoTone />
  //               <Link to="/tour-packages">Tours</Link>
  //             </li>
  //             <li>
  //               <ModeOfTravelTwoTone />
  //               <Link to="/destinations">Destinations</Link>
  //             </li>
  //             <li>
  //               <ContactPhone />
  //               <CommonLink href="/contact-us">Contact Us</CommonLink>
  //             </li>
  //             <li>
  //               <TripAdLink href="/trip-advisor">
  //                 <img
  //                   src="/icons/trip_ad8.svg"
  //                   height="30px"
  //                   width="120px"
  //                   alt="Trip Advisor"
  //                 />
  //               </TripAdLink>
  //             </li>
  //           </ul>
  //         </Nav>
  //       </Toolbar>
  //       <Box
  //         sx={{
  //           color: "#141E43",
  //           marginLeft: "auto",
  //           marginRight: "0",
  //           textAlign: "right",
  //           paddingRight: "20px",
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontSize: "1.1rem",
  //             fontWeight: 600,
  //             fontStyle: "italic",
  //           }}
  //         >
  //           <PermPhoneMsgTwoTone
  //             sx={{ verticalAlign: "middle", paddingRight: "5px" }}
  //           />
  //           Manoj: <a href="tel:+94 77 793 9190">+94 77 793 9190</a>
  //         </Typography>

  //         <Typography
  //           sx={{
  //             fontSize: "1.1rem",
  //             fontWeight: 600,
  //             fontStyle: "italic",
  //           }}
  //         >
  //           <PermPhoneMsgTwoTone
  //             sx={{ verticalAlign: "middle", paddingRight: "5px" }}
  //           />
  //           Pradeep: <a href="tel:+94 76 115 5494">+94 76 115 5494</a>
  //         </Typography>
  //       </Box>
  //     </AppBar>

  //     {children}

  //     {/* Footer */}
  //     <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
  //       <Typography variant="h6" align="center" gutterBottom>
  //         {company?.name}
  //       </Typography>
  //       <Typography
  //         variant="subtitle1"
  //         align="center"
  //         color="text.secondary"
  //         component="p"
  //       >
  //         Explore Sri Lanka with us!
  //       </Typography>
  //       {/* <Nav>
  //         <ul style={{ display: "flex" }}>
  //           <li style={{ marginRight: "10px" }}>
  //             <Link to="/">Home</Link> |
  //           </li>
  //           <li style={{ marginRight: "10px" }}>
  //             <Link to="/tour-packages">Tour Packages</Link>
  //           </li>
  //           <li style={{ marginRight: "10px" }}>
  //             <CommonLink href="/destinations">Destinations</CommonLink>
  //           </li>
  //           <li style={{ marginRight: "10px" }}>
  //             <CommonLink href="/contact-us">Contact Us</CommonLink>
  //           </li>
  //           <li style={{ marginRight: "10px" }}>
  //             <CommonLink href="/trip-advisor">Trip Advisor</CommonLink>
  //           </li>
  //         </ul>
  //       </Nav> */}
  //       <Copyright company={company} />
  //     </Box>
  //     {/* End footer */}
  //   </>
  // );

  return (
    <>
      {/* <Box sx={{ display: "flex" }}> */}
      {/* <CssBaseline /> */}
      <AppBar position="static" component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <TripAdLink href="/">
            <img
              src="/logo/AMP Holidays/AMP Holidays/AMP HOLIDAYS - 6.png"
              height="105px"
              width="150px"
              alt="Home"
            />
            {/* <TravelExploreTwoToneIcon
              color="action"
              fontFamily="medium"
              sx={{ color: "red", paddingLeft: "10px" }}
            /> */}
            {/* <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              component="span"
              fontFamily="Cursive"
              fontSize="2.0rem"
            >
              {company?.name}
            </Typography> */}
          </TripAdLink>

          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            {company?.name}
          </Typography> */}
          {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box> */}

          <Nav theme={theme}>
            <ul>
              <li>
                <HomeTwoToneIcon />
                <Link to="/">Home</Link>
              </li>
              {/* <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <HomeTwoToneIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <Link to="/" className={classes.link}>
                    Home
                  </Link>
                </Typography>
              </ListItem> */}
              <li>
                <AirportShuttleTwoTone />
                <Link to="/tour-packages">Tours</Link>
              </li>
              <li>
                <ModeOfTravelTwoTone />
                <Link to="/destinations">Destinations</Link>
              </li>
              <li>
                <CollectionsTwoToneIcon />
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <ContactPhone />
                <CommonLink href="/contact-us">Contact Us</CommonLink>
              </li>
              <li>
                <TripAdLink href="https://www.tripadvisor.com/Attraction_Review-g304138-d26671807-Reviews-AMP_Lanka-Kandy_Kandy_District_Central_Province.html">
                  <img
                    src="/icons/trip_ad8.svg"
                    height="30px"
                    width="120px"
                    alt="Trip Advisor"
                  />
                </TripAdLink>
              </li>
            </ul>
          </Nav>

          <Box
            sx={{
              color: "#141E43",
              marginLeft: "auto",
              marginRight: "0",
              textAlign: "right",
              paddingRight: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              <PermPhoneMsgTwoTone
                sx={{ verticalAlign: "middle", paddingRight: "5px" }}
              />
              Manoj: <a style={{ color:"aquamarine"}} href="tel:+94 77 793 9190">+94 77 793 9190</a>
            </Typography>

            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              <PermPhoneMsgTwoTone
                sx={{ verticalAlign: "middle", paddingRight: "5px" }}
              />
              Pradeep: <a style={{ color:"aquamarine"}} href="tel:+94 76 115 5494">+94 76 115 5494</a>
            </Typography>
          </Box>
        </Toolbar>

        {/* <Box
          sx={{
            color: "#141E43",
            marginLeft: "auto",
            marginRight: "0",
            textAlign: "right",
            paddingRight: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: 600,
              fontStyle: "italic",
            }}
          >
            <PermPhoneMsgTwoTone
              sx={{ verticalAlign: "middle", paddingRight: "5px" }}
            />
            Manoj: <a href="tel:+94 77 793 9190">+94 77 793 9190</a>
          </Typography>

          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: 600,
              fontStyle: "italic",
            }}
          >
            <PermPhoneMsgTwoTone
              sx={{ verticalAlign: "middle", paddingRight: "5px" }}
            />
            Pradeep: <a href="tel:+94 76 115 5494">+94 76 115 5494</a>
          </Typography>
        </Box> */}
      </AppBar>
      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1976d2",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      {/* <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique unde
          fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam,
          aliquam dolore excepturi quae. Distinctio enim at eligendi perferendis in
          cum quibusdam sed quae, accusantium et aperiam? Quod itaque exercitationem,
        </Typography>
      </Box> */}

      {children}

      {/* Footer */}
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          {company?.name}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Explore Sri Lanka with us!
        </Typography>
        {/* <Nav>
          <ul style={{ display: "flex" }}>
            <li style={{ marginRight: "10px" }}>
              <Link to="/">Home</Link> |
            </li>
            <li style={{ marginRight: "10px" }}>
              <Link to="/tour-packages">Tour Packages</Link>
            </li>
            <li style={{ marginRight: "10px" }}>
              <CommonLink href="/destinations">Destinations</CommonLink>
            </li>
            <li style={{ marginRight: "10px" }}>
              <CommonLink href="/contact-us">Contact Us</CommonLink>
            </li>
            <li style={{ marginRight: "10px" }}>
              <CommonLink href="/trip-advisor">Trip Advisor</CommonLink>
            </li>
          </ul>
        </Nav> */}
        <Copyright company={company} />
      </Box>
      {/* End footer */}
      {/* </Box> */}
    </>
  );
}

export default AppLayout;
