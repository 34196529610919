import * as React from "react";
// import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
// import AppBarCustom from "../common/AppBarCustom.tsx__1";
import styled from "styled-components";
import GlobalStyles from "@mui/material/GlobalStyles";
import { CompanyContext } from "../../contexts/CompanyContext";
import { Company } from "../../interfaces/company";
import { useContext } from "react";
import DestinationList from "../../components/destination-list/DestinationList";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const useStyles = makeStyles((theme) => ({
  topContainer: {
    // position: "absolute",
    // position: "relative",
    // display: "flex",

    width: "100%",
    // height: "100%",
    height: "auto",
    // height: "100vh",
    // backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    backgroundPosition: "center",
    // backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  Link: {
    textDecoration: "none",
  },
  card: {
    position: "relative",
    overflow: "hidden",
    "&:hover $media": {
      transform: "scale(1.2)",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  media: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
  },
}));

interface ContextProps {
  company: Company | null;
  setCompany: (company: Company) => void;
}

export default function Destinations() {
  const classes = useStyles();
  const { company, setCompany } = useContext(CompanyContext) as ContextProps;

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      {/* <CssBaseline /> */}
      {/* <AppBar position="relative">
        <Toolbar>
          <KitesurfingIcon sx={{ mr: 2 }}/>
          <Typography variant="h6" color="inherit" noWrap>
            AMP Holidays
          </Typography>
        </Toolbar>
      </AppBar> */}
      {/* <AppBarCustom></AppBarCustom> */}
      <main>
        <Box
          sx={{
            // bgcolor: "background.paper",
            pt: 8,
            pb: 6,
            // boxShadow:1,
          }}
        >
          <Container className={classes.topContainer}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#122979"
              gutterBottom
            >
              {/* {company?.name} Destinations */}
              Destinations
            </Typography>
            {/* <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack> */}
          </Container>
        </Box>

        <DestinationList/>
      </main>
    </ThemeProvider>
  );
}
