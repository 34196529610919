import * as React from "react";
// import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
// import AppBarCustom from "../common/AppBarCustom.tsx__1";
import styled from "styled-components";
import GlobalStyles from "@mui/material/GlobalStyles";
import SwipeableTextMobileStepper from "../../components/carousel/Carousel";
import { CompanyContext } from "../../contexts/CompanyContext";
import { Company } from "../../interfaces/company";
import { useContext } from "react";
import DestinationList from "../../components/destination-list/DestinationList";

// const CommonLink = styled.a`
//   color: white;
//   text-decoration: none;

//   &:hover {
//     color: #f00; /* Add your desired hover color */
//     /* Add any additional hover styles here */
//   }
// `;

// const Nav = styled.nav`
//   margin-left: auto;

//   ul {
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     display: flex;
//   }

//   li {
//     margin-right: 10px;

//     &:last-child {
//       margin-right: 0;
//     }

//     a {
//       color: #1976d2;
//       text-decoration: none;

//       &:hover {
//         text-decoration: underline;
//       }
//     }
//   }

//   a {
//     text-decoration: none;

//     &:hover {
//       color: #f00; /* Add your desired hover color */
//       /* Add any additional hover styles here */
//     }
//   }
// `;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const useStyles = makeStyles((theme) => ({
  Link: {
    textDecoration: "none",
  },
  card: {
    position: "relative",
    overflow: "hidden",
    "&:hover $media": {
      transform: "scale(1.2)",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  media: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
  },
}));

interface ContextProps {
  company: Company | null;
  setCompany: (company: Company) => void;
}

export default function Home() {
  const classes = useStyles();
  const { company, setCompany } = useContext(CompanyContext) as ContextProps;

  return (
    // <ThemeProvider theme={defaultTheme}>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      {/* <CssBaseline /> */}
      {/* <AppBar position="relative">
        <Toolbar>
          <KitesurfingIcon sx={{ mr: 2 }}/>
          <Typography variant="h6" color="inherit" noWrap>
          AMP Holidays
          </Typography>
        </Toolbar>
      </AppBar> */}
      {/* <AppBarCustom></AppBarCustom> */}
      <main>
        <SwipeableTextMobileStepper location={"home"} />

        {/* Hero unit */}
        <Box
          sx={{
            // bgcolor: "background.paper",
            pt: 8,
            pb: 6,
            // boxShadow:1,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#122979"
              gutterBottom
              fontFamily="cursive"
            >
              {company?.name}
            </Typography>
            <Typography variant="h5" align="center" color="#25D366" paragraph>
              Explore Sri Lanka with us. <br />
              <span>{company?.name}</span> is your leading travel and tours
              partner.
            </Typography>
            {/* <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack> */}
          </Container>
        </Box>

        <DestinationList />
      </main>
      {/* Footer */}
      {/* <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          AMP Holidays
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Explore Sri Lanka with us!
        </Typography>
        <Nav>
          <ul style={{ display: "flex" }}>
            <li style={{ marginRight: "10px" }}>
              <Link to="/">Home</Link> |
            </li>
            <li style={{ marginRight: "10px" }}>
              <Link to="/tour-packages">Tour Packages</Link> |
            </li>
            <li style={{ marginRight: "10px" }}>
              <CommonLink href="/destinations">Destinations</CommonLink> |
            </li>
            <li style={{ marginRight: "10px" }}>
              <CommonLink href="/contact-us">Contact Us</CommonLink> |
            </li>
            <li style={{ marginRight: "10px" }}>
              <CommonLink href="/trip-advisor">Trip Advisor</CommonLink>
            </li>
          </ul>
        </Nav>
        <Copyright />
      </Box> */}
      {/* End footer */}
    </ThemeProvider>
  );
}
