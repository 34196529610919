// CompanyContext.tsx

import React, { createContext, useState } from "react";
import { Company } from "../interfaces/company";

interface ContextProps { // company object to use and the setCompany function for updating it
  company: Company | null;
  setCompany: (company: Company) => void;
}

const defaultValue: ContextProps = {
  company: null,
  setCompany: () => {}, // Placeholder function if not provided
};

export const CompanyContext = createContext<ContextProps>(defaultValue);

//  default CompanyContext;

interface ProviderProps {
    initialCompany?: Company;
  children?: React.ReactNode;
}

export const CompanyProvider: React.FC<ProviderProps> = ({ initialCompany, children }) => {
    const [company, setCompany] = useState<Company | null>(initialCompany || null); // Use initial value if provided

  return (
    <CompanyContext.Provider value={{ company, setCompany }}>
      {children}
    </CompanyContext.Provider>
  );
};
