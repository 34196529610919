import React from "react";
import logo from "./logo.svg";
import "./App.css";
import AppLayout from "./components/common/AppLayout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import TourPackages from "./pages/tour-packages/TourPackages";
import { CompanyContext, CompanyProvider } from "./contexts/CompanyContext";
import { Company } from "./interfaces/company";
import Destinations from "./pages/destinations/Destinations";
import Destination from "./pages/destination/Destination";
import ContactUs from "./pages/contact-us/ContactUs";
import Gallery from "./pages/gallery/Gallery";

function App() {
  const company: Company = {
    name: "AMP Holidays",
    address: "",
    phone: "",
    email: "",
  };

  return (
    <CompanyProvider initialCompany={company}>
    <div className="App">
        {/* <header className="App-header"> */}
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        {/* <AppLayout></AppLayout> */}
        {/* </header> */}

        <Router>
          <AppLayout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/tour-packages" element={<TourPackages />} />
              <Route path="/destinations" element={<Destinations />} />
              <Route path="/destination" element={<Destination />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact-us" element={<ContactUs />} />
            </Routes>
          </AppLayout>
        </Router>
      </div>
    </CompanyProvider>
  );
}

export default App;
