import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
import styled from "styled-components";
import GlobalStyles from "@mui/material/GlobalStyles";
import { CompanyContext } from "../../contexts/CompanyContext";
import { Company } from "../../interfaces/company";
import { useContext } from "react";

const cards = [
  {
    key: 1,
    heading: "Galle",
    path: "/destination?path=galle",
    content:
      "Galle is a city on the southwest coast of Sri Lanka. It’s known for Galle Fort.",
    image: "/sri_lanka_pics/galle/5.jpg",
  },
  {
    key: 2,
    heading: "Kandy",
    path: "/destination?path=kandy",
    content:
      "Kandy is a large city in central Sri Lanka. It's set on a plateau surrounded by mountains, which are home to tea plantations and bio-diverse rain forest.",
    image: "/sri_lanka_pics/kandy/4.jpg",
  },
  {
    key: 3,
    heading: "Kalpitiya",
    path: "/destination?path=kalpitiya",
    content:
      "Kalpitiya is a coastal town located in the Puttalam District of Sri Lanka.",
    image: "/sri_lanka_pics/kalpitiya/4.jpg",
  },
  {
    key: 4,
    heading: "Colombo",
    path: "/destination?path=colombo",
    content:
      "Capital City of Sri Lanka. Attractions: Gangaramaya Temple, Galle Face Green, Independence Square.",
    image: "/sri_lanka_pics/colombo/1.jpg",
  },
  {
    key: 5,
    heading: "Sigiriya",
    path: "/destination?path=sigiriya",
    content:
      "Sigiriya or Sinhagiri is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka.",
    image: "/sri_lanka_pics/sigiriya/3.jpg",
  },
  {
    key: 6,
    heading: "Nuwara Eliya",
    path: "/destination?path=nuwara-eliya",
    content:
      "Nuwara Eliya is a city in the tea country hills of central Sri Lanka. The naturally landscaped Hakgala Botanical Gardens displays roses and tree ferns, and shelters monkeys and blue magpies.",
    image: "/sri_lanka_pics/nuwara_eliya/18.jpg",
  },
  {
    key: 7,
    heading: "Mirissa",
    path: "/destination?path=mirissa",
    content:
      "Mirissa is a small town on the south coast of Sri Lanka, located in the Matara District of the Southern Province. It is approximately 150 kilometres south of Colombo and is situated at an elevation of 4 metres above sea level.",
    image: "/sri_lanka_pics/mirissa/1.jpg",
  },
  {
    key: 8,
    heading: "Hikkaduwa",
    path: "/destination?path=hikkaduwa",
    content:
      "Hikkaduwa is a seaside resort town in southwestern Sri Lanka. It’s known for its strong surf and beaches, including palm-dotted Hikkaduwa Beach, lined with restaurants and bars.",
    image: "/sri_lanka_pics/hikkaduwa/3.jpg",
  },
  {
    key: 9,
    heading: "Benthota",
    path: "/destination?path=benthota",
    content:
      "Bentota is a resort town on Sri Lanka’s southwest coast. Its long Bentota Beach stretches north, where it becomes a sandy strip known as Paradise Island, parallel to Bentota Lagoon. Coral-rich dive sites include Canoe Rock.",
    image: "/sri_lanka_pics/benthota/1.jpg",
  },
  {
    key: 10,
    heading: "Pasikudah",
    path: "/destination?path=pasikudah",
    content:
      "Pasikudah or Pasikuda is a coastal resort town located 35 kilometers northwest of Batticaloa, Batticaloa District, Sri Lanka.",
    image: "/sri_lanka_pics/pasikudah/3.jpg",
  },
  {
    key: 11,
    heading: "Arugambay",
    path: "/destination?path=arugambay",
    content:
      "Arugam Bay, known locally as Arugam Kudah, is situated on the Indian Ocean in the dry zone of Sri Lanka's southeast coast, and a historic settlement of the ancient Batticaloa Territory.",
    image: "/sri_lanka_pics/arugambay/3.jpg",
  },
  {
    key: 12,
    heading: "Yala",
    path: "/destination?path=yala",
    content:
      "Yala National Park is the most visited and second largest national park in Sri Lanka, bordering the Indian Ocean.",
    image: "/sri_lanka_pics/yala/4.jpg",
  },
  {
    key: 13,
    heading: "Jaffna",
    path: "/destination?path=jaffna",
    content:
      "Jaffna is a city on the northern tip of Sri Lanka. Nallur Kandaswamy is a huge Hindu temple with golden arches and an ornate gopuram tower.",
    image: "/sri_lanka_pics/jaffna/1.jpg",
  },
  {
    key: 14,
    heading: "Weligama",
    path: "/destination?path=weligama",
    content:
      "Weligama, nestled along the picturesque southern coast of Sri Lanka, can be found within the Matara District of the Southern Province. This charming town is overseen by an Urban Council. The name \"Weligama\" itself translates to \"sandy village,\" an apt description of the area's scenic bay adorned with golden sands. Located about 144 kilometers (89 miles) to the south of Colombo, Weligama enjoys an elevation of 9 meters (30 feet) above sea level.",
    image: "/sri_lanka_pics/weligama/1.jpg",
  },
];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#e1edfc",
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(2),
  },
  Link: {
    textDecoration: "none",
  },
  card: {
    position: "relative",
    overflow: "hidden",
    "&:hover $media": {
      transform: "scale(1.2)",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  media: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
  },
}));

interface ContextProps {
  company: Company | null;
  setCompany: (company: Company) => void;
}

export default function DestinationList() {
  const classes = useStyles();
  const { company, setCompany } = useContext(CompanyContext) as ContextProps;

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />

      {/* <main> */}

      <Container sx={{ py: 8 }} maxWidth="md" className={classes.container}>
        {/* End hero unit */}
        <Grid container spacing={4}>
          {cards.map((card) => (
            <Grid item key={card.key} xs={12} sm={6} md={4}>
              <Link to={card.path} className={classes.Link}>
                <Card
                  className={classes.card}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    className={classes.media}
                    component="div"
                    sx={{
                      // 16:9
                      pt: "56.25%",
                      // transform:{
                      //   transitionDuration:'2s',

                      // }
                    }}
                    image={card.image}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      color="#016C8E"
                    >
                      {card.heading}
                    </Typography>
                    <Typography
                      color="#F28C61"
                      fontWeight="600"
                      fontFamily="inherit"
                    >
                      {card.content}
                    </Typography>
                  </CardContent>
                  {/* <CardActions>
                    <Button size="small">View</Button>
                    <Button size="small">Edit</Button>
                  </CardActions> */}
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* </main> */}
    </ThemeProvider>
  );
}
