import * as React from "react";
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
// import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { CompanyContext } from "../../contexts/CompanyContext";
import { Company } from "../../interfaces/company";
import { useContext } from "react";
import { PermPhoneMsgTwoTone } from "@mui/icons-material";

const CommonLink = styled.a`
  color: #1976d2;
  text-decoration: none;
  margin: auto;
  &:hover {
    color: #f00; /* Add your desired hover color */
    /* Add any additional hover styles here */
  }
`;

const defaultTheme = createTheme();

const useStyles = makeStyles((theme) => ({
  topMain: {
    // marginTop: "20px",
  },
  topContainer: {
    // backgroundColor: '#e1edfc',
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(2),

    // position: "absolute",
    // position: "relative",
    // display: "flex",

    width: "100%",
    // height: "100%",
    height: "auto",
    // height: "100vh",
    // backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // marginTop: "20px",
    paddingTop: "20px",
  },
  container: {
    backgroundColor: "#e1edfc",
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(2),
    // marginTop: "20px",
  },
  titleBG: {
    width: "100%",
    height: "auto",
    // backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // marginTop: "20px",
    // marginBottom: "20px",
    // paddingBottom: "10px",
  },
  Link: {
    textDecoration: "none",
  },
  bgImage: {
    // position: "relative",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // zIndex: -1

    // position: "absolute",
    position: "relative",
    width: "100%",
    height: "auto",
    // height: "100%",
    // height: "100vh",
    backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  media: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
  },
  grid: {
    // marginTop: "20px",
  },
  transparentCard: {
    // backgroundColor: "transparent !important",
    // boxShadow: 'none',

    // background: "#1B03A3",
    // background: "#2d71c4",
    // background: "#4D4DFF",
    opacity: "0.90",

    // backgroundColor: rgba(255, 0, 0, 0.5),
    // backgroundColor: alpha(theme.colors.red, 0.5)
    // backgroundColor: alpha(theme.palette.background.paper, 0.85)

    // hover: {
    //   // "&:hover": {
    //     backgroundColor: 'rgb(7, 177, 77, 0.42)'
    //   // },
    // }

    "&:hover": {
      backgroundColor: "#8bdab5 !important",
      // boxShadow: "none !important",
    },
    "&:active": {
      boxShadow: "none !important",
      backgroundColor: "#8bdab5 !important",
    },
  },
}));

interface ContextProps {
  company: Company | null;
  setCompany: (company: Company) => void;
}

export default function ContactUs() {
  const classes = useStyles();
  const { company, setCompany } = useContext(CompanyContext) as ContextProps;

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      {/* <main className={classes.bgImage}> */}
      <main className={classes.topMain}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
          className={classes.topContainer}
        >
          <div className={classes.titleBG}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#122979"
              gutterBottom
            >
              {/* {company?.name} Tour Packages */}
              Contact Us
            </Typography>
          </div>
          <Box
            sx={{
              color: "#4BAE8D",
              marginLeft: "auto",
              marginRight: "0",
              paddingRight: "20px",
              paddingLeft: "10px",
              marginTop: "30px",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              <PermPhoneMsgTwoTone
                sx={{ verticalAlign: "middle", paddingRight: "5px" }}
              />
              Email: <a href="mailto:amplankaholidays@gmail.com">amplankaholidays@gmail.com</a>
            </Typography>
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              <PermPhoneMsgTwoTone
                sx={{ verticalAlign: "middle", paddingRight: "5px" }}
              />
              Manoj: <a href="tel:+94 77 793 9190">+94 77 793 9190</a>
            </Typography>
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              <PermPhoneMsgTwoTone
                sx={{ verticalAlign: "middle", paddingRight: "5px" }}
              />
              Pradeep: <a href="tel:+94 76 115 5494">+94 76 115 5494</a>
            </Typography>
            <Typography
              sx={{
                fontSize: "1.3rem",
                fontWeight: 600,
                fontFamily: "inherit",
                marginTop: "30px",
              }}
            >
              Address - Colombo: AMP Holidays, 140/1A, Arachchigoda, Pitipana South,
              Homagama, Sri Lanka. (10206)
            </Typography>

            <Typography
              sx={{
                fontSize: "1.3rem",
                fontWeight: 600,
                fontFamily: "inherit",
                marginTop: "30px",
              }}
            >
              Address - Kandy: AMP Holidays, No 21 / 1, Peeligama, Pilimathalawa, Sri
              Lanka. (20450)
            </Typography>
          </Box>
        </Container>
        {/* End hero unit */}
        <Container
          maxWidth="md"
          component="main"
          className={classes.container}
        ></Container>
      </main>
    </ThemeProvider>
  );
}
