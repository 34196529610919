import * as React from "react";
import { makeStyles, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const homeImages = [
  {
    key: 1,
    label: "Sri Lanka - the Paradise Island",
    imgPath: "/sri_lanka_pics/beach_1.jpg",
  },
  {
    key: 2,
    label: "Sri Lanka - the Paradise Island",
    imgPath: "/sri_lanka_pics/galle_1.jpg",
  },
  {
    key: 3,
    label: "Sri Lanka - the Paradise Island",
    imgPath: "/sri_lanka_pics/nuwaraeliya_1.jpg",
  },
  {
    key: 4,
    label: "Sri Lanka - the Paradise Island",
    imgPath: "/sri_lanka_pics/sigiriya_2.jpg",
  },
  {
    key: 5,
    label: "Sri Lanka - the Paradise Island",
    imgPath: "/sri_lanka_pics/kandy_3_test.jpg",
  },
];

const arugambayImages = [
  {
    key: 1,
    label: "Arugambay",
    imgPath: "/sri_lanka_pics/arugambay/1.jpg",
  },
  {
    key: 2,
    label: "Arugambay",
    imgPath: "/sri_lanka_pics/arugambay/2.jpg",
  },
  {
    key: 3,
    label: "Arugambay",
    imgPath: "/sri_lanka_pics/arugambay/3.jpg",
  },
];

const benthotaImages = [
  {
    key: 1,
    label: "Benthota",
    imgPath: "/sri_lanka_pics/benthota/1.jpg",
  },
  {
    key: 2,
    label: "Benthota",
    imgPath: "/sri_lanka_pics/benthota/2.jpg",
  },
  {
    key: 3,
    label: "Benthota",
    imgPath: "/sri_lanka_pics/benthota/3.jpg",
  },
];

const colomboImages = [
  {
    key: 1,
    label: "Colombo",
    imgPath: "/sri_lanka_pics/colombo/1.jpg",
  },
  {
    key: 2,
    label: "Colombo",
    imgPath: "/sri_lanka_pics/colombo/2.jpg",
  },
  {
    key: 3,
    label: "Colombo",
    imgPath: "/sri_lanka_pics/colombo/3.jpg",
  },
];

const galleImages = [
  {
    key: 1,
    label: "Galle",
    imgPath: "/sri_lanka_pics/galle/1.jpg",
  },
  {
    key: 2,
    label: "Galle",
    imgPath: "/sri_lanka_pics/galle/2.jpg",
  },
  {
    key: 3,
    label: "Galle",
    imgPath: "/sri_lanka_pics/galle/3.jpg",
  },
];

const hikkaduwaImages = [
  {
    key: 1,
    label: "Hikkaduwa",
    imgPath: "/sri_lanka_pics/hikkaduwa/1.jpg",
  },
  {
    key: 2,
    label: "Hikkaduwa",
    imgPath: "/sri_lanka_pics/hikkaduwa/2.jpg",
  },
  {
    key: 3,
    label: "Hikkaduwa",
    imgPath: "/sri_lanka_pics/hikkaduwa/3.jpg",
  },
];

const jaffnaImages = [
  {
    key: 1,
    label: "Jaffna",
    imgPath: "/sri_lanka_pics/jaffna/1.jpg",
  },
  {
    key: 2,
    label: "Jaffna",
    imgPath: "/sri_lanka_pics/jaffna/2.jpg",
  },
  {
    key: 3,
    label: "Jaffna",
    imgPath: "/sri_lanka_pics/jaffna/3.jpg",
  },
];
const kalpitiyaImages = [
  {
    key: 1,
    label: "Kalpitiya",
    imgPath: "/sri_lanka_pics/kalpitiya/1.jpg",
  },
  {
    key: 2,
    label: "Kalpitiya",
    imgPath: "/sri_lanka_pics/kalpitiya/2.jpg",
  },
  {
    key: 3,
    label: "Kalpitiya",
    imgPath: "/sri_lanka_pics/kalpitiya/3.jpg",
  },
];

const kandyImages = [
  {
    key: 1,
    label: "Kandy",
    imgPath: "/sri_lanka_pics/kandy/1.jpg",
  },
  {
    key: 2,
    label: "Kandy",
    imgPath: "/sri_lanka_pics/kandy/2.jpg",
  },
  {
    key: 3,
    label: "Kandy",
    imgPath: "/sri_lanka_pics/kandy/3.jpg",
  },
];

const mirissaImages = [
  {
    key: 1,
    label: "Mirissa",
    imgPath: "/sri_lanka_pics/mirissa/1.jpg",
  },
  {
    key: 2,
    label: "Mirissa",
    imgPath: "/sri_lanka_pics/mirissa/2.jpg",
  },
  {
    key: 3,
    label: "Mirissa",
    imgPath: "/sri_lanka_pics/mirissa/3.jpg",
  },
];

const nuwaraEliyaImages = [
  {
    key: 1,
    label: "Nuwara Eliya",
    imgPath: "/sri_lanka_pics/nuwara_eliya/1.jpg",
  },
  {
    key: 2,
    label: "Nuwara Eliya",
    imgPath: "/sri_lanka_pics/nuwara_eliya/2.jpg",
  },
  {
    key: 3,
    label: "Nuwara Eliya",
    imgPath: "/sri_lanka_pics/nuwara_eliya/3.jpg",
  },
];

const pasikudahImages = [
  {
    key: 1,
    label: "Pasikudah",
    imgPath: "/sri_lanka_pics/pasikudah/1.jpg",
  },
  {
    key: 2,
    label: "Pasikudah",
    imgPath: "/sri_lanka_pics/pasikudah/2.jpg",
  },
  {
    key: 3,
    label: "Pasikudah",
    imgPath: "/sri_lanka_pics/pasikudah/3.jpg",
  },
];

const sigiriyaImages = [
  {
    key: 1,
    label: "Sigiriya",
    imgPath: "/sri_lanka_pics/sigiriya/1.jpg",
  },
  {
    key: 2,
    label: "Sigiriya",
    imgPath: "/sri_lanka_pics/sigiriya/2.jpg",
  },
  {
    key: 3,
    label: "Sigiriya",
    imgPath: "/sri_lanka_pics/sigiriya/3.jpg",
  },
];
const yalaImages = [
  {
    key: 1,
    label: "Yala",
    imgPath: "/sri_lanka_pics/yala/1.jpg",
  },
  {
    key: 2,
    label: "Yala",
    imgPath: "/sri_lanka_pics/yala/2.jpg",
  },
  {
    key: 3,
    label: "Yala",
    imgPath: "/sri_lanka_pics/yala/3.jpg",
  },
];
const weligamaImages = [
  {
    key: 1,
    label: "Weligama",
    imgPath: "/sri_lanka_pics/weligama/1.jpg",
  },
  {
    key: 2,
    label: "Weligama",
    imgPath: "/sri_lanka_pics/weligama/2.jpg",
  },
  {
    key: 3,
    label: "Weligama",
    imgPath: "/sri_lanka_pics/weligama/3.jpg",
  },
  {
    key: 4,
    label: "Weligama",
    imgPath: "/sri_lanka_pics/weligama/4.jpg",
  },
  {
    key: 5,
    label: "Weligama",
    imgPath: "/sri_lanka_pics/weligama/5.jpg",
  },
  {
    key: 6,
    label: "Weligama",
    imgPath: "/sri_lanka_pics/weligama/6.jpg",
  },
  {
    key: 7,
    label: "Weligama",
    imgPath: "/sri_lanka_pics/weligama/7.jpg",
  },
  {
    key: 8,
    label: "Weligama",
    imgPath: "/sri_lanka_pics/weligama/8.jpg",
  },
];

interface Props {
  location: string | null;
}

interface sliderImages {
  key: number;
  label: string;
  imgPath: string;
}

const SwipeableTextMobileStepper: React.FC<Props> = ({ location }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  // console.log("location in Carousel:", location);

  let images: sliderImages[] = [];
  switch (location) {
    case "home":
      images = homeImages;
      break;
    case "arugambay":
      images = arugambayImages;
      break;
    case "benthota":
      images = benthotaImages;
      break;
    case "colombo":
      images = colomboImages;
      break;
    case "galle":
      images = galleImages;
      break;
    case "hikkaduwa":
      images = hikkaduwaImages;
      break;
    case "jaffna":
      images = jaffnaImages;
      break;
    case "kalpitiya":
      images = kalpitiyaImages;
      break;
    case "kandy":
      images = kandyImages;
      break;
    case "mirissa":
      images = mirissaImages;
      break;
    case "nuwara-eliya":
      images = nuwaraEliyaImages;
      break;
    case "pasikudah":
      images = pasikudahImages;
      break;
    case "sigiriya":
      images = sigiriyaImages;
      break;
    case "yala":
      images = yalaImages;
      break;
    case "weligama":
      images = weligamaImages;
      break;
    default:
      break;
  }
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  //   const useStyles = makeStyles((theme) => ({
  //     root: {
  //       width: "100%",
  //       marginRight: 700,
  //     },
  //   }));

  //   const classes = useStyles();

  const springConfig = {
    duration: "0.9s",
    easeFunction: "ease",
    delay: "0s",
  };

  return (
    <Box sx={{ flexGrow: 1, margin: "auto", width: "100%" }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography
          sx={{
            margin: "auto", // font-style: normal,
            // font-weight: normal,
            fontSize: "26px",
            lineHeight: "1.2em",
            fontFamily: "Fertigo Pro",
            color: "#666",
            fontStyle: "italic",
          }}
        >
          {images[activeStep].label}
        </Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        animateTransitions
        springConfig={springConfig}
      >
        {images.map((step, index) => (
          <div key={step.key}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  //   height: 255,
                  //   maxWidth: 400,
                  display: "block",
                  overflow: "hidden",
                  width: "95%",
                  //   maxHeight: "300px",
                  margin: "auto",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
};

export default SwipeableTextMobileStepper;
