import * as React from "react";
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
// import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { CompanyContext } from "../../contexts/CompanyContext";
import { Company } from "../../interfaces/company";
import { useContext } from "react";

const tiers = [
  {
    title: "Colombo/ Kandy/ Benthota",
    // price: '0',
    description: ["5 Nights/ 6 Days"],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
  {
    title: "Customized Tour Packages",
    subheader: "Most popular",
    // price: '15',
    description: ["Customize your destinations & attractions"],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
  {
    title: "Kandy/ Galle/ Benthota/ Colombo",
    // price: '30',
    description: ["6 Nights/ 7 Days"],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
  {
    title: "Kandy/ Nuwaraeliya/ Yala National Park/ Galle/ Benthota",
    // price: '0',
    description: ["7 Nights/ 8 Days"],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
  {
    title: "Ayurveda Packages",
    subheader: "Most popular",
    // price: '15',
    description: [
      "Ayurveda Packages with Spice Garden Tours and Meditation Sessions",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
  {
    title: "Kandy/ Nuwaraeliya/ Benthota/ Colombo",
    // price: '30',
    description: ["4 Nights/ 5 Days"],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
  {
    title: "Kandy/ Nuwaraeliya/ Yala National Park/ Galle/ Benthota/ Colombo",
    // price: '0',
    description: ["7 Nights/ 8 Days"],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
  // {
  //   title: "Customized Tour Packages",
  //   subheader: "Most popular",
  //   // price: '15',
  //   description: ["Customize your destinations", "Customize your attraction"],
  //   buttonText: "Contact us",
  //   buttonVariant: "outlined",
  // },
  // {
  //   title: "Kandy/ Galle/ Benthota/ Colombo",
  //   // price: '30',
  //   description: [
  //     "Kandy/ Galle/ Benthota/ Colombo",
  //     "6 Nights/ 7 Days",
  //   ],
  //   buttonText: "Contact us",
  //   buttonVariant: "outlined",
  // },
];

const CommonLink = styled.a`
  color: #1976d2;
  text-decoration: none;
  margin: auto;
  &:hover {
    color: #f00; /* Add your desired hover color */
    /* Add any additional hover styles here */
  }
`;

const defaultTheme = createTheme();

const useStyles = makeStyles((theme) => ({
  topMain: {
    // marginTop: "20px",
  },
  topContainer: {
    // backgroundColor: '#e1edfc',
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(2),

    // position: "absolute",
    // position: "relative",
    // display: "flex",

    width: "100%",
    // height: "100%",
    height: "auto",
    // height: "100vh",
    // backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // marginTop: "20px",
    paddingTop: "20px",
  },
  container: {
    backgroundColor: "#e1edfc",
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(2),
    // marginTop: "20px",
  },
  titleBG: {
    width: "100%",
    height: "auto",
    // backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // marginTop: "20px",
    // marginBottom: "20px",
    // paddingBottom: "10px",
  },
  Link: {
    textDecoration: "none",
  },
  bgImage: {
    // position: "relative",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // zIndex: -1

    // position: "absolute",
    position: "relative",
    width: "100%",
    height: "auto",
    // height: "100%",
    // height: "100vh",
    backgroundImage: `url("/sri_lanka_pics/bgs/6.jpg")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  media: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
  },
  grid: {
    // marginTop: "20px",
  },
  transparentCard: {
    // backgroundColor: "transparent !important",
    // boxShadow: 'none',

    // background: "#1B03A3",
    // background: "#2d71c4",
    // background: "#4D4DFF",
    opacity: "0.90",

    // backgroundColor: rgba(255, 0, 0, 0.5),
    // backgroundColor: alpha(theme.colors.red, 0.5)
    // backgroundColor: alpha(theme.palette.background.paper, 0.85)

    // hover: {
    //   // "&:hover": {
    //     backgroundColor: 'rgb(7, 177, 77, 0.42)'
    //   // },
    // }

    "&:hover": {
      backgroundColor: "#E0FFC0 !important",
      // boxShadow: "none !important",
    },
    "&:active": {
      boxShadow: "none !important",
      backgroundColor: "#E0FFC0 !important",
    },

    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardSameHeight: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const StyledCard = styled(Card)({
  // "& .MuiCard-root": {
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  // }
  ".MuiCard-root.same-height": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  marginBottom: "5px",
});

interface ContextProps {
  company: Company | null;
  setCompany: (company: Company) => void;
}

export default function TourPackages() {
  const classes = useStyles();
  const { company, setCompany } = useContext(CompanyContext) as ContextProps;

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      {/* <main className={classes.bgImage}> */}
      <main className={classes.topMain}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
          className={classes.topContainer}
        >
          <div className={classes.titleBG}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#122979"
              gutterBottom
            >
              {/* {company?.name} Tour Packages */}
              Tour Packages
            </Typography>
          </div>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main" className={classes.container}>
          <Grid
            container
            spacing={5}
            alignItems="flex-end"
            className={classes.grid}
            // style={{ display: 'grid' }}
          >
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
                // style={{display: 'flex'}}
              >
                <StyledCard
                  className={classes.transparentCard}
                  // sx={{
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "space-between",
                  // }}
                >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    // sx={{
                    //   backgroundColor: (theme) =>
                    //     theme.palette.mode === "light"
                    //       ? theme.palette.grey[200]
                    //       : theme.palette.grey[700],
                    // }}
                    sx={{
                      // backgroundColor: (theme) =>
                      //   theme.palette.mode === "light"
                      //     ? theme.palette.grey[200]
                      //     : theme.palette.grey[700],
                      backgroundColor: "#80aaff",
                      height: "100px",
                    }}
                  />
                  <CardContent
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      // fontStyle: "italic",
                      color: "#F28C61",
                      fontFamily: "inherit !important",
                      height: "70px",
                    }}
                  >
                    {/* <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /mo
                      </Typography>
                    </Box> */}
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                          sx={{
                            fontWeight: 600,
                            color: "#F28C61",
                            fontFamily: "inherit",
                          }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    {/* <Button
                      fullWidth
                      variant={tier.buttonVariant as "outlined" | "contained"}
                    >
                      {tier.buttonText}
                    </Button> */}
                    {/* <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        console.info("I'm a button.");
                      }}
                    >
                      {tier.buttonText}
                    </Link> */}
                    <CommonLink href="/contact-us">
                      {tier.buttonText}
                    </CommonLink>
                  </CardActions>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}
